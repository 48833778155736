<template>
  <div>
    <div class="flexRow justify-content-end" v-if="matchJurisdiction('AdminUser/add')">
      <el-button type="primary" @click="openBox(1)">新建</el-button>
    </div>
    <div class="marginTop">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="序号" prop="num" width="80" align="center"></el-table-column>
        <el-table-column label="账号" prop="account" align="center"></el-table-column>
        <el-table-column label="姓名" prop="realname" align="center"></el-table-column>
        <el-table-column label="操作" align="center" v-if="matchJurisdiction('AdminUser/edit')||matchJurisdiction('AdminUser/del')">
          <template slot-scope="scope">
            <span v-if="matchJurisdiction('AdminUser/edit')" class="editBtn mRight" @click="openBox(2,scope.$index)">编辑</span>
            <span v-if="matchJurisdiction('AdminUser/del')" class="editBtn" @click="delItem(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page flexRow justify-content-center">
        <el-pagination
            background
            layout="total,prev, pager, next,jumper"
            :current-page="curPage"
            :total="totalItem"
            :page-size="20"
            @current-change="toPages">
        </el-pagination>
      </div>
    </div>
    <!--新建、编辑-->
    <el-dialog :visible.sync="showBox" width="30%">
      <el-form label-width="90px">
        <el-form-item label="真实姓名">
          <el-input v-model="formData.realname" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="账号名称" required>
          <el-input v-model="formData.nickname" placeholder="请输入账号名"></el-input>
        </el-form-item>
        <el-form-item label="密码" required v-if="eid==''">
          <el-input v-model="formData.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="所属角色" required>
          <el-select v-model="formData.role_id" style="width: 100%" placeholder="请选择角色">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-end">
        <el-button type="primary" @click="subMsg">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from 'js-md5';

export default {
  name: "Users",
  data(){
    return{
      tableData:[],
      curPage:1,
      totalItem:0,
      formData:{
        nickname:"",
        password:"",
        role_id:"",
        realname:"",
      },
      roleList:[],
      showBox:false,
      eid:"",
    }
  },
  created(){
    sessionStorage.setItem('dActive','accountList');
    this.getList(1)
  },
  mounted(){

  },
  methods:{
    //获取账号列表
    async getList(p){
      let res = await this.mainFun('AdminUser/getList',{p})
      let data = res.data.data;
      this.totalItem = res.data.total;
      for(let i in data){
        data[i].num = (res.data.current_page - 1) * res.data.per_page + parseInt(i) + 1
      }
      this.tableData = data;
    },
    //分页
    toPages(p){
      this.p = p;
      this.curPage = p;
      this.getList(p)
    },
    //获取角色
    async getRoleList(){
      let res = await this.mainFun('AdminRole/getList',{list_rows:50})
      this.totalItem = res.data.total;
      let data = res.data.data;
      this.roleList = data;
    },
    //打开弹窗
    openBox(type,index){
      if(type == 1){
        this.eid = "";
        this.formData = {
          nickname: '',
          password:'',
          role_id:'',
          realname:"",
        }
      }else{
        this.formData = {
          nickname:this.tableData[index].account,
          role_id:this.tableData[index].role_id,
          realname:this.tableData[index].realname
        }
        this.eid = this.tableData[index].id;
      }
      this.showBox = true;
      this.getRoleList()
    },
    //删除
    delItem(id){
      this.$confirm('是否确认删除当前信息','提示',{
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning"
      }).then(async ()=>{
        let res = await this.mainFun('AdminUser/del',{id});
        if(res.data.code == 1){
          this.$message.success(res.data.message);
          this.getList(this.p);
        }else{
          this.$message.error(res.data.message);
        }
      })
    },
    //新建、编辑
    async subMsg(){
      if(this.formData.nickname == ""){
        this.$message.warning("请填写账号名称");
        return
      }
      if(this.formData.password == ""){
        this.$message.warning("请填写密码");
        return
      }
      if(this.formData.role_id == ""){
        this.$message.warning("请选择角色");
        return
      }
      let params = {
        account:this.formData.nickname,
        role_id:this.formData.role_id,
        realname:this.formData.realname
      };
      let url = 'AdminUser/';
      if(this.eid){
        params.id = this.eid;
        url += 'edit'
      }else{
        url += 'add';
        params.password = md5(this.formData.password + 'log')
      }
      let res = await this.mainFun(url,params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        if(params.id){
          await this.getList(this.p);
        }else{
          await this.getList(1);
        }
        this.showBox = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
